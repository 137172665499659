<template>
  <FilterForm
    ref="FilterForm"
    :value="value"
    @request="onSubmit"
    @reset="onReset"
  >
    <VInputDate
      ref="VInputDate"
      label="По дате"
      :value="dateStart"
      :value-end="dateFinish"
      class="grid-filter--all-line"
    />

    <VSelect
      label="По табам цехов"
      name="calendar_tab"
      :options="tabs"
      multiple
    />

    <VSelect
      label="По типам таблиц"
      name="type"
      :options="tableTypes"
      multiple
    />
  </FilterForm>
</template>

<script>
import FilterForm from '@/components/Form/FilterForm'
import VInputDate from '@/components/Form/VInputDate.vue'
import VSelect from '@/components/Form/VSelect'
import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import { mapState } from 'vuex'
import { LoadCalendarModel } from '@/entities/load-calendar'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import { transformDateForSend } from '@/utils/form/date'

export default {
  name: 'TheCatalogFilter',
  components: { VInputDate, VSelect, FilterForm },
  mixins: [mixinFilterEmit],
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapState('handbook/tabTableTimeAndLoad', {
      tabs: 'list'
    }),

    tableTypes () {
      return LoadCalendarModel.TABLE_TYPES
    },

    dateStart () {
      return this.value?.date_start ?? null
    },

    dateFinish () {
      return this.value?.date_finish ?? null
    }
  },

  methods: {
    onSubmit (data) {
      const preparedData = prepareFormDataIdArray(data, ['calendar_tab', 'type'])
      const dateForSend = transformDateForSend(this.$refs.VInputDate.$refs.dateRef?.defaultValue)

      this.request({
        ...preparedData,
        ...dateForSend
      }, {
        ...data,
        ...dateForSend
      })
    },

    onReset () {
      this.$refs.FilterForm.$refs.form.clear()
      this.$refs.VInputDate.$refs.dateRef._flatpickr.clear()

      this.reset()
    }
  }
}
</script>
